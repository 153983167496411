<template>
    <div>
        <component
            :is="customComponent"
            v-if="customComponent"
        />
        <div v-if="isLoading" class="w-100" style="height:100px">
            <div class="pb-3">
                <skeleton width="200px" height="20px" />
            </div>
            <skeleton :count="2" />
            <div class="pt-4">
                <skeleton :count="2" />
            </div>
            <div class="pt-4">
                <skeleton :count="2" />
            </div>
        </div>
    </div>
</template>

<script>
import { Skeleton } from "vue-loading-skeleton";
import CONFIG from "@/config/app-vars";

export default {
    name: "Dashboard",
    components: {
        Skeleton
    },
    data() {
        return {
            isLoading: false,
            customComponent: null
        };
    },
    mounted() {
        this.customComponent = this.loadComponend(this.$route.name);
    },
    methods: {
        loadComponend(resourceName) {
            const hosts = location.host.split(".").slice(-2);
            const host0 = CONFIG.domain || hosts[0];
            const defaultPath = "./domains/gewaer";
            const domain = `./domains/${host0}`;
            const resource = resourceName;

            return () => import(
                /* webpackChunkName: "dashboard" */ `${domain}/${resource}/`
            )
                .catch(() => {
                    return import(
                        /* webpackChunkName: "dashboard-[resource]" */ `${defaultPath}/${resource}/`
                    );
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>
