<template>
    <th
        v-if="isHeader"
        class="vuetable-th-component-status-date text-right"
        @click="orderBy(rowField, $event)"
        v-html="title"
    />
    <td
        v-else
        class="vuetable-td-component-status-date"
    >
        <div class="text-right">
            <span> {{ value }}</span>
            <lead-stopwatch
                :key="rowData.id"
                :lead="rowData"
                @start="onStopwatchStart"
                @stop="onStopwatchStop"
            >
                <template v-slot:loading>
                    <lead-stopwatch-skeleton class="justify-content-end">
                        <template v-slot:append>
                            <skeleton
                                class="ml-2"
                                width="20px"
                                height="20px"
                                :loading="true"
                            />
                        </template>
                    </lead-stopwatch-skeleton>
                </template>
                <template v-slot:stopwatch="{ stopwatch }">
                    <span class="text-blue lead-stopwatch">
                        <span class="lead-stopwatch__timer">{{ stopwatch }}</span>
                        <i class="fas fa-history ml-2" />
                    </span>
                </template>

                <template v-slot:visits="{ daysActive }">
                    <span class="ml-2 text-primary">
                        {{ daysActive }} Visits
                    </span>
                </template>
            </lead-stopwatch>
        </div>
    </td>
</template>

<script>
import { Skeleton } from "vue-loading-skeleton";
import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin.vue";
import LeadStopwatch from "@/views/domains/gewaer/leads/stopwatch.vue";
import LeadStopwatchSkeleton from "@/views/domains/gewaer/leads/stopwatch-skeleton.vue";

export default {
    name: "VuetableStopwatchField",
    components: {
        Skeleton,
        LeadStopwatch,
        LeadStopwatchSkeleton
    },
    mixins: [VuetableFieldMixin],
    computed: {
        value() {
            return this.rowData[this.rowField.fieldName];
        }
    },
    methods: {
        onStopwatchStart(data) {
            this.updateLeadStopwatch(data);
        },
        onStopwatchStop(data) {
            this.updateLeadStopwatch(data);
            this.$set(this.rowData, "leads_visits_count", data.leads_visits_count);
        },
        updateLeadStopwatch(data) {
            this.$set(this.rowData, "chrono_start_date", data.chrono_start_date);
            this.$set(this.rowData, "is_chrono_running", data.is_chrono_running);
        },
        orderBy(rowData, $event) {
            this.vuetable.orderBy(rowData, $event);
        }
    }
}
</script>


<style lang="scss">
.vuetable-td-component-link a {
    color: var(--execlist-color-blue);

    &:hover {
        color: var(--execlist-color-blue);
    }
}

.text-blue {
    color: dodgerblue;
}
</style>

<style lang="scss" scoped>
// fix the width of the stopwatch so it doesn't move other columns
.lead-stopwatch {
   display: flex;
    align-items: center;
    justify-content: flex-end;

    &__timer {
        width: 66px;
        max-width: 66px;
    }
}
</style>
