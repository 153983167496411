import * as slug from "slugify";
import { toLower } from "lodash";
import textIsURL from "is-url";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { isYesterday, isThisWeek, isThisYear, isToday, format } from "date-fns";
import moment from "moment";
import { DEFAULT_THEME } from "@config/constants";

export function generateUUID() { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== "undefined") && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        let r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {// Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export function isValidJWT(jwt) {
    return /^([a-z0-9_=]{4,})\.([a-z0-9_=]{4,})\.([a-z0-9_\-+/=]{4,})/i.test(
        jwt
    );
}

export function slugify(value) {
    return slug(value);
}

export function lowercase(value) {
    return toLower(value);
}

export function isURL(str) {
    return textIsURL(str);
}

export function hexToHSL(H) {
    // Convert hex to RGB first
    let r = 0,
        g = 0,
        b = 0;

    if (H.length == 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
    } else if (H.length == 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin;
    let h = 0,
        s = 0,
        l = 0;

    if (delta == 0) {
        h = 0;
    } else if (cmax == r) {
        h = ((g - b) / delta) % 6;
    } else if (cmax == g) {
        h = (b - r) / delta + 2;
    } else {
        h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    if (h < 0) {
        h += 360;
    }

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l, css: `hsl(${h},${s}%,${l}%)` };
}

export function hexToHSLA(hex, modifiers = {}) {
    const { h, s, l } = hexToHSL(hex);
    const hue = h + (modifiers.h || 0);
    const saturation = s + (modifiers.s || 0);
    const light = l + (modifiers.l || 0);
    const alpha = modifiers.a || 1;

    return {
        h: hue,
        s: saturation,
        l: light,
        a: alpha,
        css: `hsla(${hue},${saturation}%,${light}%,${alpha})`
    }
}

export function addCSSProperties(properties) {
    const documentRoot = document.documentElement;
    for (const key in properties) {
        documentRoot.style.setProperty(key, properties[key]);
    }
}

export function setAppTheme(settings = {}) {
    const {
        base_color: appBaseColor = DEFAULT_THEME.BASE_COLOR,
        secondary_color: appSecondaryColor = DEFAULT_THEME.SECONDARY_COLOR
    } = settings;

    const hslColors = {
        "--base-color": hexToHSL(appBaseColor).css,
        "--darken-base-color": hexToHSLA(appBaseColor, { s: -10, l: -40 }).css,
        "--secondary-color": hexToHSL(appSecondaryColor).css
    };
    addCSSProperties(hslColors);
}

/**
 * Importing the PhoneNumber type definition
 * @typedef { import("libphonenumber-js/types").PhoneNumber } PhoneNumber
 */

/**
 * Finds and format a phone number from a string
 * @param {string} text String that contains the phone number to be formatted
 * @param {string} countryCode Country code in two-letter ISO format that determines the format. {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements|Supported country codes}
 * @returns {PhoneNumber|undefined} PhoneNumber instance based on the phone and country code or undefined if there's no phone.
 */
export function formatPhone(text, countryCode = "US") {
    return parsePhoneNumberFromString(text, countryCode);
}

/**
 * Finds and format a phone number from a string with national format
 * @param {string} text String that contains the phone number to be formatted
 * @param {string} countryCode Country code in two-letter ISO format that determines the format. {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements|Supported country codes}
 * @returns {string|""} Phone number with the national format or empty string if there's an error
 */
export function formatPhoneNational(text, countryCode) {
    const rawPhone = formatPhone(text, countryCode);
    return (rawPhone && rawPhone.formatNational()) || "";
}

/**
 * Filter files passed by extension
 * @param {array<string>} fileTypes a list of extensions to be filtered
 * @param {array<string>} files a list of Kanvas Files objects
 * @returns {array}
 */
export function filterFilesByType(fileTypes, files) {
    return files ? files.filter(file => {
        return fileTypes.includes(file.file_type.toLowerCase());
    }) : [];
}

/**
 * Formats a timestamp to date object
 * @param {<number> | <string>} timestamp a number or string representing a date
 * @returns {object<date>}
 */
export function formatToJsDate(timestamp, timezone = "") {
    const day = timestamp ? moment.utc(timestamp).tz(timezone) : "";

    if (!day) {
        return day;
    }

    return day.toDate();
}

/**
 * Formats date object to a readable date
 * @param {object<date>} dateObject
 * @returns {string} Formatted date string
 */
export function formatDate(date) {
    // date-js cause is already loaded and have better utilities
    if (isToday(date)) {
        return format(date, "'Today at' HH:mm bbb");
    } else if (isYesterday(date)) {
        return format(date, "'Yesterday at' HH:mm bbb");
    } else if (isThisWeek(date)) {
        return format(date, "EEEE 'at' HH:mm bbb");
    } else if (isThisYear(date)) {
        return format(date, "MMM dd 'at' HH:mm bbb");
    } else {
        return format(date, "MMM dd, yyyy 'at' HH:mm bbb");
    }
}

/**
 * Formats an object params query into an string params
 * @param {object<date>} dateObject
 * @returns {string} Formatted date string
 */
export function convertElasticSearch(params) {
    const search = Object.entries(params).map(([param, value]) => {
        return `${param}:${value}`
    }).join(",");
    return `(chs.${search})`
}

/**
 * Get the user name for the activity: the first and lastname of the activity's user
 * or "You" if the activity's user is the current one.
 *
 * @param {object<User>} currentUser logged in user.
 * @param {object<Activity>} activity feed's activity
 * @returns {string} Formatted username string
 */
export function getActivityUserName(currentUser, activity) {
    const user = activity.users || {};
    const username = `${user.firstname} ${user.lastname}`;
    // @todo later we need to change that to use the user_uuid of the activity.message custom property.
    return currentUser.id == activity.users.id ? "You" : username;
}
