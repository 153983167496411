<template>
    <div v-if="isReady" class="container-fluid pipelines">
        <div class="row">
            <div class="col">
                <h1 class="section-title p-l-10">
                    {{ pipeline.name }}
                    <i
                        v-if="pipeline.is_default == 1"
                        class="fas fa-check"
                        title="This is the default pipeline"
                    />
                </h1>
            </div>
            <div class="col-auto pipelines-dropdown">
                <dropdown ref="pipelines" :x="dropdownOffsetX">
                    <template slot="btn">
                        {{ pipeline.name }}
                    </template>
                    <div slot="body" class="container-fluid">
                        <div
                            v-for="item in pipelines"
                            :key="`pipeline-${item.id}`"
                            class="row pipeline-item"
                            @click="changePipeline(item.id)"
                        >
                            <div class="col px-2">
                                {{ item.name }}
                            </div>
                            <div class="col-auto">
                                <i v-if="item.id == id" class="fas fa-check" />
                                <i class="fas fa-edit" @click="editPipeline(item.id)" />
                            </div>
                        </div>
                        <div class="row pipeline-item new-pipeline" @click="newPipeline()">
                            <div class="col px-2">
                                <i class="fas fa-plus" />
                                New pipeline
                            </div>
                        </div>
                        <div class="row pipeline-item edit-pipeline" @click="editPipeline(pipeline.id)">
                            <div class="col px-2">
                                <i class="fas fa-edit" />
                                Edit pipeline
                            </div>
                        </div>
                    </div>
                </dropdown>
            </div>
        </div>
        <div class="row">
            <div v-for="stage in stages" :key="`stage-${stage.id}`" class="col">
                <div class="stage-title card text-white bg-dark">
                    <div class="card-body">
                        <div>{{ stage.name }}</div>
                        <div v-if="leads[stage.id]">
                            {{ leads[stage.id].length }}
                            {{ leads[stage.id].length == 1 ? "deal" : "deals" }}
                        </div>
                        <div v-else>
                            0 deals
                        </div>
                    </div>
                </div>
                <draggable
                    v-model="leads[stage.id]"
                    :sort="false"
                    draggable=".card"
                    group="leads"
                    @add="(event) => updateLeadStage(event, stage.id)"
                >
                    <div v-for="lead in leads[stage.id]" :key="`lead-${lead.id}`" class="card">
                        <div class="card-body">
                            {{ lead.title }}
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
import _groupBy from "lodash/groupBy";
import draggable from "vuedraggable";

export default {
    name: "Pipeline",
    components: {
        draggable
    },
    data() {
        return {
            dropdownOffsetX: 0,
            id: 0,
            isReady: false,
            leads: null,
            pipeline: null,
            pipelines: null,
            stages: null
        };
    },
    watch: {
        "$route.params.id": {
            async handler(id) {
                if (id) {
                    this.isReady = false;
                    this.id = id;
                    await this.fetchPipelineData();
                    await this.fetchPipelineLeads();
                    await this.fetchPipelineStages();
                    this.isReady = true;
                }
            },
            immediate: true
        }
    },
    async created() {
        await this.fetchPipelines();
    },
    async updated() {
        // Hack the dropdown for proper positioning.
        // Haven't found another way to do it without having a fixed length on the dropdown body.
        if (this.$refs.pipelines) {
            await (this.$refs.pipelines.isHidden = false);
            const dropdown = this.$refs.pipelines.$el.querySelector(".bp-dropdown__body");
            const selector = this.$refs.pipelines.$el.querySelector(".bp-dropdown__btn");
            this.dropdownOffsetX = -(dropdown.offsetWidth - selector.offsetWidth - 1);
            this.$refs.pipelines.isHidden = true;
            const wrapper = document.querySelector(".pipelines-dropdown");
            wrapper && wrapper.classList.remove("pipelines-dropdown");
        }
    },
    methods: {
        changePipeline(pipelineId) {
            this.$router.replace({
                name: "pipeline",
                params: {
                    id: pipelineId
                }
            });
        },
        editPipeline(pipelineId) {
            this.$router.replace({
                name: "pipelineFormEdit",
                params: {
                    id: pipelineId
                }
            });
        },
        async fetchPipelines() {
            await axios({
                url: "/pipelines"
            }).then(({ data }) => {
                if (this.id === 0) {
                    this.$router.replace({
                        name: "pipeline",
                        params: {
                            id: data[0].id
                        }
                    });
                }

                this.pipelines = data;
            }).catch(() => {
                if (this.pipelines === null) {
                    this.newPipeline();
                }
            });
        },
        async fetchPipelineData() {
            await axios({
                url: `/pipelines/${this.id}`
            }).then(({ data }) => {
                this.pipeline = data;
            });
        },
        async fetchPipelineLeads() {
            await axios({
                url: `/leads?q=(pipeline_id:${this.id})`
            }).then(({ data }) => {
                this.leads = _groupBy(data, "pipeline_stage_id");
            });
        },
        async fetchPipelineStages() {
            await axios({
                url: `/pipelines/${this.id}/stages?sort=weight|asc`
            }).then(({ data }) => {
                this.stages = data;
            });
        },
        newPipeline() {
            this.$router.replace({
                name: "pipelineFormAdd"
            });
        },
        updateLeadStage(event, stageId) {
            const lead = event.item._underlying_vm_;

            axios({
                url: `/leads/${lead.id}`,
                method: "PUT",
                data: {
                    pipeline_stage_id: stageId
                }
            }).catch(() => {
                const leadItem = this.leads[stageId].splice(event.newIndex, 1);
                this.leads[lead.pipeline_stage_id].splice(event.oldIndex, 0, leadItem[0]);

                this.$notify({
                    title: "Error",
                    text: "Something went wrong when trying to update lead stage.",
                    type: "error"
                });
            });
        }
    }
}
</script>

<style lang="scss">
.pipelines {
    .container-fluid {
        width: 100% !important;
        padding: 0 15px !important;
    }

    .card {
        margin-bottom: 10px;
    }

    .pipelines-dropdown {
        .bp-dropdown__body {
            visibility: hidden;
        }
    }

    .bp-dropdown__body {
        .pipeline-item {
            padding: 5px 10px;

            &:hover {
                background-color: #dee2e6;
                cursor: pointer;

                .fa-edit {
                    display: inline-block;
                }

                .fa-check {
                    display: none;
                }
            }

            .fa-edit {
                display: none;
            }
        }

        .new-pipeline {
            border-top: 1px solid #dee2e6;
        }

        .edit-pipeline {
            .fa-edit {
                display: inline-block;
            }
        }

        .fas {
            font-size: 80%;
        }

        .fa-check {
            color: #317be2;
        }
    }
}
</style>
