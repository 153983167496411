<template>
    <div class="needs-analysis">
        <h4 class="section-title">
            Needs Analysis
            <label>
                <input v-model="status" name="status" type="checkbox">
                Active
            </label>
        </h4>
        <span class="add-field" @click="addField()">
            <i class="fa fa-plus" />
            Add Field
        </span>
        <form @submit.prevent="submit()">
            <div class="form-content">
                <div
                    v-for="(field, index) in content"
                    :key="`field-${index}`"
                    :class="{ 'odd-row': index % 2 }"
                    class="row form-group"
                >
                    <div class="col-sm-4">
                        <tooltip
                            :manual="true"
                            :value="errors.has(`label-${index}`)"
                            content="required"
                            placement="top-end"
                            effect="dark"
                        >
                            <input
                                v-model="content[index].label"
                                v-validate="{ required: true }"
                                :class="{ error: errors.has(`label-${index}`) }"
                                :name="`label-${index}`"
                                type="text"
                                class="form-control"
                            >
                        </tooltip>
                    </div>
                    <div class="col-sm-3">
                        <multiselect
                            v-model="content[index].type"
                            :allow-empty="false"
                            :custom-label="(field) => fields[field]"
                            :options="fieldTypes"
                            :show-labels="false"
                        />
                    </div>
                    <div class="col-sm-4">
                        <div v-if="content[index].type === 'select'" class="row">
                            <div class="col-sm-12">
                                <tooltip
                                    :manual="true"
                                    :value="errors.has(`textarea-${index}`)"
                                    content="required"
                                    placement="top-end"
                                    effect="dark"
                                >
                                    <textarea
                                        v-model="options[index]"
                                        v-validate="{ required: true }"
                                        :class="{ error: errors.has(`textarea-${index}`) }"
                                        :name="`textarea-${index}`"
                                        class="form-control"
                                        rows="4"
                                        @input="updateOptions(index, 'select')"
                                    />
                                </tooltip>
                            </div>
                        </div>
                        <div v-if="content[index].type === 'range-slider'" class="row range-slider">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label>min</label>
                                        <tooltip
                                            :manual="true"
                                            :value="errors.has(`slider-min-${index}`)"
                                            :content="errors.first(`slider-min-${index}`)"
                                            placement="top-end"
                                            effect="dark"
                                        >
                                            <input
                                                v-model="content[index].fields['range-slider'].attrs.min"
                                                v-validate="{ required: true, numeric: true, max_value: content[index].fields['range-slider'].attrs.max - 1 }"
                                                :class="{ error: errors.has(`slider-min-${index}`) }"
                                                :name="`slider-min-${index}`"
                                                type="text"
                                                class="form-control"
                                            >
                                        </tooltip>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>max</label>
                                        <tooltip
                                            :manual="true"
                                            :value="errors.has(`slider-max-${index}`)"
                                            :content="errors.first(`slider-max-${index}`)"
                                            placement="top-end"
                                            effect="dark"
                                        >
                                            <input
                                                v-model="content[index].fields['range-slider'].attrs.max"
                                                v-validate="{ required: true, numeric: true, min_value: content[index].fields['range-slider'].attrs.min + 1 }"
                                                :class="{ error: errors.has(`slider-max-${index}`) }"
                                                :name="`slider-max-${index}`"
                                                type="text"
                                                class="form-control"
                                            >
                                        </tooltip>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label>min label</label>
                                        <tooltip
                                            :manual="true"
                                            :value="errors.has(`slider-minLabel-${index}`)"
                                            content="required"
                                            placement="top-end"
                                            effect="dark"
                                        >
                                            <input
                                                v-model="content[index].fields['range-slider'].attrs.minLabel"
                                                v-validate="{ required: true }"
                                                :class="{ error: errors.has(`slider-minLabel-${index}`) }"
                                                :name="`slider-minLabel-${index}`"
                                                type="text"
                                                class="form-control"
                                            >
                                        </tooltip>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>max label</label>
                                        <tooltip
                                            :manual="true"
                                            :value="errors.has(`slider-maxLabel-${index}`)"
                                            content="required"
                                            placement="top-end"
                                            effect="dark"
                                        >
                                            <input
                                                v-model="content[index].fields['range-slider'].attrs.maxLabel"
                                                v-validate="{ required: true }"
                                                :class="{ error: errors.has(`slider-maxLabel-${index}`) }"
                                                :name="`slider-maxLabel-${index}`"
                                                type="text"
                                                class="form-control"
                                            >
                                        </tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="content[index].type === 'option-group'" class="row option-group">
                            <div class="col-sm-4">
                                <label>
                                    <input v-model="content[index].fields['option-group'].attrs.multiple" type="checkbox">
                                    Allow multiple?
                                </label>
                            </div>
                            <div class="col-sm-8">
                                <textarea
                                    v-model="options[index]"
                                    class="form-control"
                                    rows="4"
                                    @input="updateOptions(index, 'option-group')"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1 remove-field">
                        <i v-if="content.length > 1" class="fa fa-times" @click="removeField(index)" />
                    </div>
                </div>
            </div>
            <div>
                <button type="submit" class="btn btn-primary">
                    Save
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { Tooltip } from "element-ui";

export default {
    name: "ActionsNeedsAnalysis",
    components: {
        Tooltip
    },
    data() {
        return {
            actionId: 0,
            content: [],
            isLoading: true,
            fields: {
                "input": "Text Field",
                "email": "Email Field",
                "number": "Number Field",
                "tel": "Telephone Field",
                "textarea": "Text Area",
                "select": "Dropdown",
                "range-slider": "Slider",
                "option-group": "Option Group"
            },
            options: {},
            optionsFields: ["select", "option-group"],
            status: false
        };
    },
    computed: {
        fieldTypes() {
            return Object.keys(this.fields);
        }
    },
    created() {
        this.$validator.localize("en", {
            messages: {
                required: "required",
                numeric: "numbers only",
                min_value: "max has to be higher than min",
                max_value: "min has to be lower than max"
            }
        });

        this.initialize();
    },
    methods: {
        async initialize() {
            await this.fetchData();
            this.isLoading = false;
        },
        async fetchData() {
            await axios({
                url: "/actions/needs-analysis"
            }).then(({ data: action }) => {
                this.formatContent(Object.values(action.form_config));
                if (this.content.length === 0) {
                    this.addField();
                }
                this.actionId = action.actions_id;
                this.status = Boolean(Number(action.is_active));
            });
        },
        formatContent(content) {
            this.content = content.map((item, index) => {
                item.fields = {
                    [item.type]: {
                        ...(item.options && { options: item.options }),
                        ...(item.attrs && { attrs: { ...item.attrs } })
                    }
                };

                this.options[index] = (item.options || []).join("\n");

                Object.keys(this.fields).filter((field) => field !== item.type).forEach((field) => {
                    item.fields[field] = this.mapFieldType(field);
                });

                return item;
            });
        },
        prepareContent() {
            const schema = {};

            this.content.forEach((content, index) => {
                schema[`question_${index}`] = {
                    label: content.label,
                    type: content.type,
                    ...(content.fields[content.type].options && { options: content.fields[content.type].options }),
                    ...(content.fields[content.type].attrs && { attrs: content.fields[content.type].attrs })
                };
            });

            return schema;
        },
        addField() {
            const row = {
                label: "",
                type: "input",
                fields: {}
            };

            Object.keys(this.fields).forEach((field) => {
                row.fields[field] = this.mapFieldType(field);
            });

            this.content.push(row);
        },
        removeField(index) {
            this.content.splice(index, 1);
            this.$validator.reset();
        },
        mapFieldType(field) {
            const item = {};

            switch (field) {
                case "select":
                    item.options = [];
                    break;
                case "range-slider":
                    item.attrs = {
                        min: 1,
                        max: 10,
                        minLabel: "Poor",
                        maxLabel: "Perfect",
                        step: 1,
                        tooltips: 1
                    };
                    break;
                case "option-group":
                    item.options = [];
                    item.attrs = {
                        multiple: false
                    };
                    break;
            }

            return item;
        },
        async submit() {
            const isValid = await this.$validator.validateAll();

            if (!isValid) {
                return;
            }

            const data = this.prepareContent();

            axios({
                method: "PUT",
                url: `/actions/${this.actionId}`,
                data: {
                    actions_id: this.actionId,
                    form_config: JSON.stringify(data),
                    is_active: this.status
                }
            });
        },
        updateOptions(index, type) {
            this.content[index].fields[type].options = this.options[index].split("\n");
        }
    }
}
</script>

<style lang="scss" scoped>
.needs-analysis {
    position: relative;

    h4.section-title {
        label {
            font-size: 80%;
            margin-left: 100px;
            text-transform: capitalize;
        }
    }

    .add-field {
        color: var(--base-color);
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        user-select: none;
    }

    .form-content {
        > div.row {
            border-bottom: 1px solid #aaa;
            margin-bottom: 0;
            padding: 15px 0;

            &:nth-child(2n) {
                background-color: #eee;
            }

            &:hover {
                background-color: #ccc;
            }

            &:first-child {
                border-top: 1px solid #aaa;
            }

            &:last-child {
                margin-bottom: 15px;
            }
        }

        input[type="text"],
        textarea {
            padding-left: 12px;

            &.error {
                background-color: rgba(220, 53, 69, 0.1);
            }
        }

        .range-slider {
            label {
                margin-bottom: 0;
            }
        }

        .add-video-link {
            display: flex;
            justify-content: flex-end;
            color: var(--base-color);

            span {
                cursor: pointer;
                font-size: 80%;
                user-select: none;
            }
        }

        .option-group {
            label {
                cursor: pointer;
            }
        }

        .remove-field {
            display: flex;
            align-items: center;
            justify-content: center;

            .fa-times {
                font-size: 24px;
                color: #dc3545;
                cursor: pointer;
            }
        }
    }
}
</style>
