<template>
    <div class="actions-file-modal p-3">
        <h5 class="pb-3 mb-3">
            Editing Schemas: {{ file.title }}
        </h5>
        <div class="file-modal__content mb-3 pb-3">
            <div class="container">
                <div class="row">
                    <div class="col-3">
                        <div class="mb-2">
                            PDF Schema
                        </div>
                        <textarea v-model="file.pdf_schema" rows="20" />
                    </div>
                    <div class="col-3">
                        <div class="mb-2">
                            Form Schema
                        </div>
                        <textarea v-model="file.form_schema" rows="20" />
                    </div>
                    <div class="col-3">
                        <div class="mb-2">
                            Linked Fields
                        </div>
                        <textarea v-model="file.linked_fields" rows="20" />
                    </div>
                    <div class="col-3">
                        <div class="mb-2">
                            Initial Values
                        </div>
                        <textarea v-model="file.initial_values" rows="20" />
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right">
            <button type="button" class="btn btn-success mr-4" @click="save()">
                Update
            </button>
            <button type="button" class="btn btn-secondary" @click="cancel()">
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActionsFileModal",
    props: {
        file: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        cancel: {
            type: Function,
            required: true
        },
        save: {
            type: Function,
            required: true
        }
    },
    created() {
        this.file.pdf_schema = JSON.stringify(this.file.pdf_schema);
        this.file.form_schema = JSON.stringify(this.file.form_schema);
        this.file.linked_fields = JSON.stringify(this.file.linked_fields);
        this.file.initial_values = JSON.stringify(this.file.initial_values);
    }
}
</script>

<style lang="scss" scoped>
.actions-file-modal {
    h5 {
        border-bottom: 1px solid #ccc;
        color: #555;
    }

    .file-modal__content {
        border-bottom: 1px solid #ccc;

        textarea {
            border: 1px solid #ccc;
            font-family: Consolas, Monaco, "Courier New", monospace;
            font-size: 14px;
            resize: none;
            width: 100%;
        }
    }
}
</style>
