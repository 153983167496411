import FieldsStopwatch from "./fields/field-stopwatch.vue";
import FieldTitle from "./fields/field-lead-title.vue";
import { formatPhoneNational } from "@/utils/helpers";
import moment from "moment";

const fields = {
    leads: [
        {
            name: FieldsStopwatch,
            field: "leads_visits_count",
            title: "Visits",
            sortField: "leads_visits_count",
            width: "110px"
        },
        {
            name: FieldTitle,
            field: "title",
            sortField: "title"
        },
        {
            name: "people.phone",
            formatter(phone) {
                if (!phone) {
                    return;
                }

                return formatPhoneNational(phone);
            }
        },
        {
            name: "created_at",
            formatter(date) {
                let formattedDay = "";
                if (date) {
                    const diff = moment().diff(moment(date), "days");
                    formattedDay = diff > 0 ? moment(date).fromNow() : "Today";
                }

                return `
                <span class="capitalize">
                    ${formattedDay}
                    <i class="fas fa-calendar"></i>
                </span>`;
            }
        }
    ],
    peoples: [
        {
            name: "phone",
            formatter(phone) {
                if (!phone) {
                    return;
                }

                return formatPhoneNational(phone);
            }
        }
    ]
};

export default {
    getFields(resource) {
        return fields[resource] ? fields[resource] : [];
    }
};
