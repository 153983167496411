import CONFIG from "./app-vars.js"

import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es"

VeeValidate.Validator.extend("step", {
    getMessage: (field, [step]) => `The ${field} must be a multiple of ${step}.`,
    validate: (value, [step]) => !(value % step)
});

if (CONFIG.domain == "deslindate") {
    Validator.localize({ es: es });
    Vue.use(VeeValidate, { fieldsBagName: "vvFields", locale: "es" });
} else {
    Vue.use(VeeValidate, { fieldsBagName: "vvFields" });
}
