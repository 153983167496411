<template>
    <div class="actions-manager">
        <gw-browse
            ref="gwBrowse"
            :resource="resource"
            :http-options="{ baseURL, headers: { Authorization: token } }"
            :query-params="queryParams"
            :append-params="{ format: true, limit: 100 }"
            :extra-fields="extraFields"
            :show-resource-actions="false"
            :show-bulk-actions="false"
            :show-pagination="false"
            pagination-path=""
        >
            <template v-slot:actions="props">
                <div class="d-flex align-items-center justify-content-end" style="height:fit-content">
                    <button
                        :class="{ 'btn-primary': !props.rowData.is_active, 'btn-danger': props.rowData.is_active }"
                        type="button"
                        class="btn"
                        @click="toggleStatus(props.rowData)"
                    >
                        {{ props.rowData.is_active ? "Deactivate" : "Activate" }}
                    </button>
                </div>
            </template>
        </gw-browse>
    </div>
</template>

<script>
const { GwBrowse } = require(`@/import.${process.env.VUE_APP_IMPORTS}`);

export default {
    name: "ActionsManager",
    components: {
        GwBrowse
    },
    data() {
        return {
            baseURL: process.env.VUE_APP_BASE_API_URL,
            token: this.$store.state.User.token || Cookies.get("token"),
            resource: {
                name: "Manage Actions",
                slug: "actions"
            },
            queryParams: {
                sort: "sort",
                page: "page",
                perPage: "limit"
            },
            extraFields: [
                {
                    name: "is_active",
                    formatter(status) {
                        return Number(status) === 1 ? `<i class="fa fa-check" />` : `<i class="fa fa-times" />`;
                    }
                }
            ]
        };
    },
    methods: {
        toggleStatus(action) {
            axios({
                url: `/actions/${action.actions_id}`,
                method: "PUT",
                data: {
                    actions_id: action.actions_id,
                    is_active: Number(!action.is_active)
                }
            }).then(() => {
                action.is_active = Number(!action.is_active);
            });
        }
    }
}
</script>

<style lang="scss">
.actions-manager {
    .fa-check {
        color: var(--base-color);
    }

    .fa-times {
        color: #dc3545;
    }
}
</style>
