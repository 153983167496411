<template>
    <div class="stopwatch-skeleton">
        <slot name="prepend" />
        <skeleton :width="width" :height="height" />
        <span>:</span>
        <skeleton :width="width" :height="height" />
        <span>:</span>
        <skeleton :width="width" :height="height" />
        <slot name="append" />
    </div>
</template>

<script>
import { Skeleton } from "vue-loading-skeleton"

export default {
    name: "LeadStopwatchSkeleton",
    components: {
        Skeleton
    },
    props: {
        width: {
            type: String,
            default: "20px"
        },
        height: {
            type: String,
            default: "20px"
        }
    }
}
</script>

<style lang="scss" scoped>
.stopwatch-skeleton {
    display: flex;
    align-items: center;
    color: #EEEEEE;
}
</style>
