<template>
    <div class="browse-table" :class="{ deslindate: isDeslindate }">
        <gw-browse
            ref="gwBrowse"
            :append-params="appendParams"
            :http-options="{ baseURL, headers: { Authorization: token }}"
            :pagination-data="paginationData"
            :extra-fields="getFields(resource.slug || resource.name)"
            :query-params="queryParams"
            :resource="resource"
            :search-options="searchOptions"
            :search-placeholder="searchPlaceholder"
            :show-bulk-actions="false"
            :show-search-filters="false"
            pagination-path=""
            :app-search="useAppSearch"
            @load-error="loadError"
        >
            <template v-slot:actions="props">
                <div class="d-flex align-items-center justify-content-end" style="height:fit-content">
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="editResource(props.rowData)"
                    >
                        <i class="fa fa-edit" />
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger"
                        @click="deleteResource(props.rowData.id)"
                    >
                        <i class="fa fa-trash" />
                    </button>
                </div>
            </template>

            <template v-if="resource.slug=='leads' && loadingText" v-slot:loading>
                <div class="loading py-4">
                    <img src="https://mc-canvas.s3.amazonaws.com/progress-circle.svg" width="48" height="48">
                    <p class="m-0 mt-1 p-0">
                        {{ loadingText }}
                    </p>
                </div>
            </template>
        </gw-browse>
    </div>
</template>

<script>
const { GwBrowse } = require(`@/import.${process.env.VUE_APP_IMPORTS}`);
import CONFIG from "@config/app-vars.js"
import { mapState } from "vuex";
import tableFields from "./tableFields.js";
import resourceParams from "./resourceParams.js";

export default {
    name: "Browse",
    components: {
        GwBrowse
    },
    data() {
        return {
            CONFIG,
            isExpanded: false,
            baseURL: process.env.VUE_APP_BASE_API_URL,
            queryParams: {
                sort: "sort",
                page: "page",
                perPage: "limit"
            },
            token: this.$store.state.User.token || Cookies.get("token"),
            loadingText: ""
        }
    },
    computed: {
        ...mapState({
            resources: state => state.Application.resources
        }),
        appendParams() {
            const appendParams = {
                format: "true"
            };
            const resource = this.$route.params.resource;

            if (resource in resourceParams) {
                for (const [key, value] of Object.entries(resourceParams[resource].appendParams)) {
                    appendParams[key] = value;
                }
            }

            return appendParams;
        },
        useAppSearch() {
            // @todo: this should be refactored with an option in the resource entity that has app-search
            const resourcesSlugsWithAppSearch = ["leads", "peoples"];
            return resourcesSlugsWithAppSearch.includes(this.resource.slug);
        },
        resource() {
            const resources = [];
            this.resources.forEach(link => {
                if (link.links) {
                    resources.push(link, ...link.links)
                } else {
                    resources.push(link)
                }
            });
            return resources.find(resource => resource.slug == this.$route.params.resource);
        },
        isDeslindate() {
            return CONFIG.domain == "deslindate";
        },
        searchOptions() {
            const resource = this.$route.params.resource;

            if (resource in resourceParams) {
                return resourceParams[resource].searchOptions || {};
            }

            return {};
        },
        searchPlaceholder() {
            const maps = {
                leads: "open leads"
            };
            const resource = this.resource.title.toLowerCase();

            return `Search ${maps[resource] || resource}...`;
        }
    },
    methods: {
        getFields: tableFields.getFields,
        loadError(error) {
            this.$notify({
                title: "Error",
                text: error.response.data.errors.message,
                type: "error"
            });
        },
        paginationData(data) {
            const paginationData = {
                total: parseInt(data.total_rows),
                per_page: parseInt(data.limit),
                current_page: parseInt(data.page),
                last_page: parseInt(data.total_pages)
            }

            const nextParams = this.$refs.gwBrowse.getAllQueryParams();
            nextParams.page = nextParams.page == paginationData.last_page ? null : nextParams.page + 1;
            const prevParams = this.$refs.gwBrowse.getAllQueryParams();
            prevParams.page = prevParams.page == 1 ? null : prevParams.page - 1;

            const nextQuery = Object.keys(nextParams).map(key => `${key}=${nextParams[key]}`);
            const prevQuery = Object.keys(prevParams).map(key => `${key}=${prevParams[key]}`);

            paginationData.next_page_url = nextParams.page === null ? null : `${this.baseURL}?${nextQuery.join("&")}&format=true`;
            paginationData.prev_page_url = prevParams.page === null ? null : `${this.baseURL}?${prevQuery.join("&")}&format=true`;
            paginationData.from = (paginationData.current_page - 1) * paginationData.per_page + 1;
            paginationData.to = paginationData.from + paginationData.per_page - 1;

            return paginationData;
        },
        createResource() {
            if (this.resource.create_template && this.resource.create_template.includes("modal")) {
                this.$modal.show(this.resource.create_template, {
                    resource: this.resource
                });
            } else {
                this.$router.push({
                    name: "create-resource",
                    params: {
                        resource: this.resource.slug
                    }
                });
            }
        },
        editResource(record) {
            this.$router.push({
                name: "edit-resource",
                params: {
                    id: record.id,
                    resource: this.resource.route || this.resource.slug
                }
            })
        },
        async reload() {
            await this.$refs.gwBrowse.$refs.Vuetable.reload()
            this.loadingText = "";
        },
        // Add this delay to add more time between reloading the data
        // due to elastic taking some time to return deleted records
        delayLoad(text) {
            this.loadingText = text;
            this.$refs.gwBrowse.loading = true;
            const delay = (fn) => {
                setTimeout(() => {
                    fn();
                }, 2000);
            };
            return delay;
        },
        deleteResource(id) {
            if (confirm("Are you sure you want to delete this record?")) {
                const url = this.resource.endpoint || this.resource.slug
                const delay = this.delayLoad("Deleting the lead and it's resources");
                axios({
                    method: "DELETE",
                    url: `${url}/${id}`
                }).then(() => {
                    if (this.resource.slug == "leads") {
                        delay(this.reload)
                    } else {
                        this.reload();
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss">
.browse-table {
    position: relative;
    .table-container {
        border: 0;
        box-shadow: 0 7px 14px rgba(132, 132, 132, 0.15);
        padding: 20px;
        border-radius: 5px;
        background-color: white;
    }
}

.deslindate {
    .table-actions,
    .browse-actions,
    .pagination-controls,
    .vuetable-th-component-status-date,
    .vuetable-td-component-status-date,
    .vuetable-th-phone,
    .vuetable-td-phone {
        display: none !important;
    }
}
</style>
