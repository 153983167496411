import Vue from "vue";
import App from "./App.vue";
import "./config";
import store from "./store";
import router from "./router";
import "@/components/global-styles";
import "@/components/global";
import * as VueGoogleMaps from "vue2-google-maps";
import VueObserveVisibility from "vue-observe-visibility";
import Pusher from "pusher-js";
import Cleave from "vue-cleave-component";
import "intersection-observer";
require("cleave.js/dist/addons/cleave-phone.us");

Vue.use(VueObserveVisibility);
Vue.use(Cleave);
Vue.use(VueGoogleMaps, {
    load: {
        region: "DO",
        language: "ES",
        key: process.env.VUE_APP_GOOGLE_MAP_KEY
    }
})


Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
    cluster: "us2" || process.env.VUE_APP_PUSHER_CLUSTER
})

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
