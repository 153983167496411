export default [
    {
        id: "actions-3xcd",
        title: "Actions",
        slug: "actions",
        links: [
            {
                is_published: 1,
                name: "Manage Actions",
                title: "Manage Actions",
                route: {
                    name: "actions-manager"
                },
                icon_class: "fa fa-car"
            },
            {
                is_published: 1,
                name: "Dealer Content",
                title: "Dealer Content",
                route: {
                    name: "actions-dealer-content"
                },
                icon_class: "fa fa-car"
            },
            {
                is_published: 1,
                name: "Download App",
                title: "Download App",
                route: {
                    name: "actions-download-app"
                },
                icon_class: "fa fa-car"
            },
            {
                is_published: 1,
                name: "Trade Walk",
                title: "Trade Walk",
                route: {
                    name: "actions-trade-walk"
                },
                icon_class: "fa fa-car"
            },
            {
                is_published: 1,
                name: "Needs Analysis",
                title: "Needs Analysis",
                route: {
                    name: "actions-needs-analysis"
                },
                icon_class: "fa fa-car"
            },
            {
                is_published: 1,
                name: "Get Referrals",
                title: "Get Referrals",
                route: {
                    name: "actions-get-referrals"
                },
                icon_class: "fa fa-car"
            },
            {
                is_published: 1,
                name: "eSign Docs",
                title: "eSign Docs",
                route: {
                    name: "actions-esign-docs"
                },
                icon_class: "fa fa-car"
            },
            {
                is_published: 1,
                name: "CC Auth",
                title: "CC Auth",
                route: {
                    name: "actions-cc-auth"
                },
                icon_class: "fa fa-car"
            }
        ]
    }
];
