<template>
    <h4 class="section-title">
        {{ title }}
        <label v-if="!isLoading">
            <input
                :checked="value"
                name="status"
                type="checkbox"
                @input="$emit('input', !value)"
            >
            Active
        </label>
    </h4>
</template>

<script>
export default {
    name: "ActionsHeader",
    props: {
        value: {
            type: [Boolean, Number],
            default: false
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
h4.section-title {
    margin-bottom: 25px;

    label {
        font-size: 80%;
        margin-left: 100px;
        text-transform: capitalize;
    }
}
</style>
