
export default {
    focusFirstError(scroll = true) {
        const multiSelect = "multiselect__input";
        const field = this.$el.querySelector(`[name=${this.errors.items[0].field}]`);

        if (field === null) {
            return;
        }

        if (!field.classList.contains(multiSelect)) {
            field.focus();
        }
        scroll && field.scrollIntoView();
    },
    formatFileSystem(file) {
        return {
            id: file.filesystem_id ? file.id : null,
            filesystem_id: file.filesystem_id ? file.filesystem_id : file.id,
            field_name: "files"
        }
    },
    getTableData(apiUrl, options) {
        return axios({
            url: apiUrl,
            params: options.params
        });
    },
    uids() {
        return Math.random().toString(16).replace(".", "");
    }
}
