<template>
    <modal
        :draggable="draggable"
        :adaptive="true"
        :scrollable="true"
        name="basic-modal"
        height="auto"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="basic-modal">
            <div class="row modal-header">
                <div class="col d-flex align-items-center">
                    <h5>{{ title }}</h5>
                    <a
                        v-if="showClose"
                        class="ml-auto"
                        href="#"
                        @click.prevent="$modal.hide('basic-modal')"
                    >
                        x
                    </a>
                </div>
            </div>
            <div class="row modal-body">
                <div class="col">
                    <div slot="container" class="row  m-b-20">
                        <div class="col-12" v-html="message" />
                    </div>
                </div>
            </div>
            <div class="row modal-footer">
                <div v-for="(button, index) in buttons" :key="'button-' + index" class="col">
                    <button :class="button.class" class="btn btn-block" @click="button.handler">
                        {{ button.title }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>

export default {
    name: "BasicModal",
    data() {
        return {
            buttons: [],
            draggable: true,
            title:"",
            message:"",
            showClose: true
        }
    },
    methods: {
        beforeOpen(event) {
            this.buttons = event.params.buttons;
            this.draggable = event.params.draggable;
            this.title = event.params.title || "";
            this.message = event.params.message || " Are you Sure?";
            this.showClose = event.params.showClose;
        },
        beforeClose() {
            this.buttons = [];
            this.draggable = true;
            this.title = "";
            this.message = "";
            this.showClose = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.basic-modal {
    .modal-header {
        h5 {
            color: var(--base-color);
            margin: 0;
        }
    }

    .section-title {
        font-weight: 700;
    }
}
</style>
