<template>
    <div class="card">
        <h5 class="uploader-title">
            {{ title }}
        </h5>
        <div class="uploader-container">
            <div class="uploader-title">
                {{ message }}
            </div>
            <el-upload
                ref="uploader"
                :auto-upload="false"
                :file-list="fileList"
                :multiple="true"
                :on-change="fileChange"
                :show-file-list="false"
                action=""
                accept=".pdf"
                class="uploader"
            >
                <button slot="trigger" type="button" class="btn btn-primary mr-4">
                    select file(s)
                </button>
                <button
                    :disabled="!fileList.length || uploadInProgress"
                    type="button"
                    class="btn btn-success"
                    @click="upload"
                >
                    upload files
                </button>
            </el-upload>
            <div class="uploader-bar">
                <el-progress v-if="isUploading" :percentage="uploadPercentage" />
            </div>
        </div>
        <div class="file-list">
            <div v-for="(file, index) in fileList" :key="`file-${file.uid}`" class="file-item">
                <div class="file-title">
                    <label>Title:</label>
                    <input v-model="fileTitle[file.uid]">
                </div>
                <div v-if="!fileEditing[file.uid]" class="file-name" @dblclick="editName(file)">
                    <i class="fa fa-file-o" />
                    {{ file.name }}
                </div>
                <div v-if="fileEditing[file.uid]" class="file-edit">
                    <input
                        v-model="fileName[file.uid]"
                        v-focus
                        @keydown.enter="editName(file)"
                        @keydown.esc="cancelEdit(file)"
                    >
                </div>
                <div class="file-actions">
                    <i class="fa fa-pencil-square-o" @click="editName(file)" />
                    <i class="fa fa-remove" @click="removeFile(file, index)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createLoadingTask } from "pdfvuer";

export default {
    name: "ActionsUploader",
    directives: {
        focus: {
            inserted: (el) => {
                el.focus();
                el.setSelectionRange(0, el.value.length);
            }
        }
    },
    props: {
        title: {
            type: String,
            default: "Upload Files"
        },
        message: {
            type: String,
            default: "Select some PDFs to upload."
        },
        type: {
            type: String,
            required: true
        },
        uploadInProgress: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fileList: [],
            fileName: {},
            fileTitle: {},
            fileEditing: {},
            isUploading: false,
            uploadPercentage: 0
        }
    },
    methods: {
        async fileChange(_file, fileList) {
            const fileEditing = {};
            const fileName = {};
            const fileTitle = {};
            for (const file of fileList) {
                fileEditing[file.uid] = this.fileEditing[file.uid] || false;
                fileName[file.uid] = this.fileName[file.uid] || file.name.replace(".pdf", "");
                fileTitle[file.uid] = this.fileTitle[file.uid] || fileName[file.uid];
                await createLoadingTask(URL.createObjectURL(file.raw)).then((pdf) => {
                    file.pages = pdf.numPages;
                });
            }
            this.fileEditing = fileEditing;
            this.fileName = fileName;
            this.fileTitle = fileTitle;
            this.fileList = fileList;
        },
        cancelEdit(file) {
            this.fileName[file.uid] = file.name.replace(".pdf", "");
            this.fileEditing[file.uid] = false;
        },
        editName(file) {
            if (this.fileEditing[file.uid]) {
                file.name = `${this.fileName[file.uid]}.pdf`;
            }
            this.fileEditing[file.uid] = !this.fileEditing[file.uid];
        },
        removeFile(file, index) {
            this.$delete(this.fileEditing, file.uid);
            this.$delete(this.fileName, file.uid);
            this.fileList.splice(index, 1);
        },
        upload() {
            this.isUploading = true;
            this.$emit("uploading", this.isUploading);
            const fileList = this.$refs.uploader.fileList;
            const formData = new FormData();

            for (const file of fileList) {
                formData.append("file[]", file.raw, `${this.fileName[file.uid]}.pdf`);
            }

            axios({
                method: "POST",
                url: "/filesystem",
                data: formData,
                onUploadProgress: (progress) => {
                    this.uploadPercentage = Math.floor((progress.loaded / progress.total) * 100);
                }
            }).then(({ data }) => {
                const titles = Object.values(this.fileTitle);
                const files = data.map((file, index) => {
                    file.pages = this.fileList[index].pages,
                    file.title = titles[index];

                    return file;
                });
                this.isUploading = false;
                this.$emit("uploading", this.isUploading);
                this.fileEditing = {};
                this.fileName = {};
                this.fileTitle = {};
                this.fileList = [];
                this.$emit("upload", files, this.type);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
h5.uploader-title {
    border-bottom: 1px solid #ccc;
    color: #555;
    font-size: 16px;
    margin-bottom: 10px;
    padding-bottom: 8px;
}

.empty-list {
    border-bottom: 1px solid #ccc;
    color: #999;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 10px;
    padding: 5px;
}

.uploader-container {
    border-bottom: 1px dashed #ccc;
    padding-bottom: 10px;

    .uploader-title {
        color: #555;
        padding-bottom: 4px;
    }

    .uploader-bar {
        width: 50%
    }

    .btn-success:disabled {
        opacity: 0.45;
    }
}

.file-list {
    .file-item {
        align-items: center;
        display: flex;
        height: 40px;
        padding: 0 5px;

        .file-name,
        .file-edit {
            width: 300px;
        }

        .file-title {
            margin-right: 10px;
            width: 350px;

            label {
                margin: 0 10px 0 0;
            }
        }

        .file-actions {
            display: none;

            i {
                cursor: pointer;
                margin-right: 10px;
            }

            .fa-pencil-square-o {
                color: var(--primary);
            }

            .fa-remove {
                color: var(--red);
            }
        }

        &:hover {
            background-color: #f0f0f0;
            .file-actions {
                display: block;
            }
        }
    }
}
</style>
