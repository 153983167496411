import { render, staticRenderFns } from "./signing-docs.vue?vue&type=template&id=6fdf53c2&scoped=true&"
import script from "./signing-docs.vue?vue&type=script&lang=js&"
export * from "./signing-docs.vue?vue&type=script&lang=js&"
import style0 from "./signing-docs.vue?vue&type=style&index=0&id=6fdf53c2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fdf53c2",
  null
  
)

export default component.exports