<template>
    <div v-if="isReady" class="container-fluid pipelines-form">
        <form @submit.prevent="">
            <div class="row justify-content-between">
                <div class="col-6">
                    <div class="form-group pipeline-title">
                        <input
                            v-model="formData.name"
                            class="form-control mr-4"
                            name="title"
                            type="text"
                        >
                        <input
                            id="is_default"
                            v-model="formData.is_default"
                            type="checkbox"
                            name="is_default"
                        >
                        <label for="is_default">Default Pipeline</label>
                    </div>
                </div>
                <div class="col-2 text-right">
                    <button type="button" class="btn btn-secondary" @click="cancel()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-success ml-2" @click="save()">
                        Save
                    </button>
                </div>
            </div>
            <draggable
                v-model="formData.stages"
                class="row stages-cols"
                draggable=".col"
                group="stages"
                handle=".stage-handle"
            >
                <div v-for="(stage, index) in formData.stages" :key="`stage-${index}`" class="col">
                    <div class="stage-title card text-white bg-dark stage-handle">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <div>{{ stage.name }}</div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-grip-lines-vertical" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stage-title card">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="">
                                    Name
                                </label>
                                <input
                                    v-model="stage.name"
                                    class="form-control"
                                    name="title"
                                    type="text"
                                >
                            </div>
                        </div>
                        <div :class="{ disabled: formData.stages.length == 1 }" class="card-footer" @click="deleteStage(index)">
                            <i class="fas fa-trash" />
                            Delete Stage
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="stage-title card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <div>Add new stage</div>
                                    <div>Pipeline stages represent the steps in your sales process</div>
                                    <button type="button" class="btn btn-success" @click="addStage()">
                                        <i class="fas fa-plus" />
                                        New stage
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </draggable>
        </form>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "PipelinesForm",
    components: {
        draggable
    },
    data() {
        return {
            formData: {
                name: "New Pipeline",
                stages: [
                    {
                        name: "Qualified"
                    },
                    {
                        name: "Contact Made"
                    },
                    {
                        name: "Demo Schedules"
                    },
                    {
                        name: "Proposal Made"
                    },
                    {
                        name: "Negotiations Started"
                    }
                ]
            },
            isEditing: typeof this.$route.params.id !== "undefined",
            isReady: false,
            pipelineId: 0
        };
    },
    async created() {
        if (!this.isEditing) {
            this.isReady = true;
        } else {
            this.pipelineId = this.$route.params.id;
            await this.fetchPipeline();
            await this.fetchPipelineStages();
            this.isReady = true;
        }
    },
    methods: {
        addStage() {
            this.formData.stages.push({
                name: "New Stage"
            });
        },
        cancel() {
            this.$router.replace({
                name: "pipeline"
            });
        },
        deleteStage(index) {
            if (this.formData.stages.length > 1) {
                this.formData.stages.splice(index, 1);
            }
        },
        async fetchPipeline() {
            await axios({
                url: `/pipelines/${this.pipelineId}`
            }).then(({ data }) => {
                data.is_default = Boolean(Number.parseInt(data.is_default));
                this.formData = data;
            });
        },
        async fetchPipelineStages() {
            await axios({
                url: `/pipelines/${this.pipelineId}/stages`
            }).then(({ data }) => {
                this.$set(this.formData, "stages", data);
            });
        },
        save() {
            const data = this.formData;
            let url = "/pipelines";
            let method = "POST";

            if (this.isEditing) {
                url = `/pipelines/${this.pipelineId}`;
                method = "PUT";
            }

            // Apply weight based on column order.
            this.formData.stages.forEach((stage, index) => {
                stage.weight = index;
            });

            data.is_default = Number(data.is_default);

            axios({
                url,
                method,
                data: this.formData
            }).then(({ data }) => {
                this.$router.replace({
                    name: "pipeline",
                    params: {
                        id: data.id
                    }
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.pipelines-form {
    .pipeline-title {
        .form-control[type="text"] {
            display: inline-block;
            width: auto;
        }

        label {
            cursor: pointer;
        }
    }

    .stages-cols {
        .stage-handle {
            cursor: move;
        }

        .form-group {
            &:last-child {
                margin-bottom: 0;
            }

            label {
                margin-bottom: 0;
            }
        }

        .card-footer {
            background-color: transparent;
            font-size: 80%;
            margin-top: 20px;
            user-select: none;

            &:hover {
                cursor: pointer;
            }

            &.disabled {
                cursor: not-allowed;
                opacity: 0.25;
            }
        }
    }
}
</style>
