<template>
    <div class="get-referrals">
        <h4 class="section-title">
            Get Referrals
            <label>
                <input v-model="status" name="status" type="checkbox">
                Active
            </label>
        </h4>
        <form @submit.prevent="submit()">
            <div class="form-content">
                <div class="row">
                    <div class="col-sm-6">
                        <tooltip
                            :manual="true"
                            :value="errors.has('amount')"
                            :content="errors.first('amount')"
                            placement="top-end"
                            effect="dark"
                        >
                            <input
                                v-model="amount"
                                v-validate="{ required: true, decimal: 0 }"
                                :class="{ error: errors.has('amount') }"
                                name="amount"
                                type="number"
                                step="1"
                                min="0"
                                class="form-control"
                            >
                        </tooltip>
                    </div>
                </div>
            </div>
            <div>
                <button type="submit" class="btn btn-primary">
                    Save
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { Tooltip } from "element-ui";

export default {
    name: "ActionsGetReferrals",
    components: {
        Tooltip
    },
    data() {
        return {
            actionId: 0,
            isLoading: true,
            amount: 0,
            status: false
        };
    },
    created() {
        this.$validator.localize("en", {
            messages: {
                required: "required",
                min_value: "amount has to be 0 or greater",
                decimal: "amount must be a whole number"
            }
        });

        this.initialize();
    },
    methods: {
        async initialize() {
            await this.fetchData();
            this.isLoading = false;
        },
        async fetchData() {
            await axios({
                url: "/actions/get-referrals"
            }).then(({ data: action }) => {
                this.amount = action.form_config.amount || 0;
                this.actionId = action.actions_id;
                this.status = Boolean(Number(action.is_active));
            });
        },
        async submit() {
            const isValid = await this.$validator.validateAll();

            if (!isValid) {
                return;
            }

            axios({
                method: "PUT",
                url: `/actions/${this.actionId}`,
                data: {
                    actions_id: this.actionId,
                    form_config: JSON.stringify({
                        amount: this.amount,
                        currency: "USD"
                    }),
                    is_active: this.status
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.get-referrals {
    position: relative;

    h4.section-title {
        margin-bottom: 25px;

        label {
            font-size: 80%;
            margin-left: 100px;
            text-transform: capitalize;
        }
    }

    .form-content {
        margin-bottom: 15px;
    }
}
</style>
