<template>
    <div>
        <slot v-if="isStopwatchLoading" name="loading" />
        <slot v-else-if="stopwatch" name="stopwatch" v-bind="{ stopwatch }">
            <span> {{ stopwatch }}</span>
        </slot>
        <slot v-else name="visits" v-bind="{daysActive}">
            <span>{{ daysActive }} Visits</span>
        </slot>
    </div>
</template>

<script>
import Tracker from "@/utils/tracker.js";

const STOPWATCH_CLICKED_EVENT = "LEADS";

export default {
    name: "LeadStopwatch",
    props: {
        lead: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            stopwatch: null,
            tracker: null,
            channel: null
        }
    },
    computed: {
        isStopwatchLoading() {
            return this.tracker && !this.stopwatch;
        },
        daysActive() {
            return this.lead.leads_visits_count || 0;
        },
        pusherChannelName() {
            const { id } = this.lead;
            return id ? `chat-${id}` : "";
        }
    },
    beforeDestroy() {
        if (this.channel) {
            this.channel.unsubscribe(this.pusherChannelName);
            this.channel = null;
        }
    },
    mounted() {
        this.initPusher();
        this.trackTime(this.lead.chrono_start_date);
    },
    methods: {
        //@todo: make the init and destroy reactive to a lead change
        initPusher() {
            if (this.pusherChannelName) {
                this.channel = this.$pusher.subscribe(this.pusherChannelName);
                this.channel.bind(STOPWATCH_CLICKED_EVENT, this.clickedStopwatch);
            }
        },
        clickedStopwatch(data) {
            if (data.lead_uuid != this.lead.uuid) {
                return;
            }

            const event = data.is_chrono_running ? "start" : "stop";
            this.$emit(event, {
                chrono_start_date: data.chrono_start_date,
                is_chrono_running: data.is_chrono_running,
                leads_visits_count: data.leads_visits_count
            });
            this.trackTime(data.chrono_start_date);
        },
        trackTime(startDate) {
            if (startDate) {
                this.tracker = this.tracker || new Tracker();
                this.tracker.trackTime(startDate, duration => {
                    this.stopwatch = duration;
                })
            } else if (this.tracker) {
                this.tracker.stop();
                this.tracker = null;
                this.stopwatch = null;
            }
        }
    }
}
</script>
