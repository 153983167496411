<template>
    <div>
        <actions-header v-model="actionData.is_active" :is-loading="isLoading" title="Dealer Content" />
        <categories-videos
            v-if="actionData.form_config"
            ref="categories"
            :categories="actionData.form_config"
        />
        <div>
            <button v-if="isSaving" type="button" class="btn loading-btn">
                <loader :size="21" />
            </button>
            <button type="submit" class="btn btn-primary" @click="saveAction()">
                Save
            </button>
        </div>
    </div>
</template>

<script>
import ActionsHeader from "./components/header";
import CategoriesVideos from "./templates/categories-videos";

export default {
    name: "ActionsDealerContent",
    components: {
        ActionsHeader,
        CategoriesVideos
    },
    data() {
        return {
            actionData: {},
            isLoading: true,
            isSaving: false
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        async initialize() {
            await this.fetchData();
        },
        async fetchData() {
            await axios({
                url: `/actions/dealer-content`
            }).then(({ data: action }) => {
                this.actionData = action;
                this.isLoading = false;
            });
        },
        async saveAction() {
            const categories = await this.$refs.categories.getContent();

            if (!categories) {
                return;
            }

            this.isSaving = true;
            this.actionData.form_config = categories;

            axios({
                method: "PUT",
                url: `/actions/${this.actionData.id}`,
                data: {
                    ...this.actionData,
                    form_config: JSON.stringify(this.actionData.form_config)
                }
            }).then(() => {
                this.isSaving = false;
            });
        }
    }
}
</script>
