export const FORMS = {
    DEFAULT_MAX_LENGTH_INPUT: 255,
    DEFAULT_MAX_LENGTH_TEXTAREA: 32000
}

export const WithOwner = [
    "Propetario",
    "Comprador",
    "Inquilino",
    "Representante",
    "Otro"
]

export const APPLICANT_TYPES = {
    INDEPENDENT: "Independiente",
    PRIVATE_EMPLOYEE:"Empleado privado",
    PUBLIC_EMPLOYEE: "Empleado publico",
    ENTREPRENEUR: "Empresario / Emprendedor"
}

export const EQUIPMENT_TYPES = {
    OWN: "Propios",
    RENTED: "Rentados",
    LENT: "Prestados"
}

export const TOPOGRAPHIC_EQUIPMENT = [
    "Estación total",
    "Receptores GNSS",
    "Equipos de nivelación"
]

export const SOFTWARE_EXPERTISE = [
    "Manejo básico",
    "Manejo intermedio",
    "Alto rendimiento"
]

export const banks = [
    "BANCO POPULAR",
    "BANCO BHD LEON",
    "BANRESERVAS",
    "SCOTIABANK",
    "SANTA CRUZ",
    "BANCO CARIBE",
    "ASOC. LA NACIONAL",
    "ASOC. POPULAR DE AHORROS Y PRESTAMOS",
    "ASOC. BONAO DE AHORROS Y PRESTAMOS",
    "ASOC. CIBAO DE AHORROS Y PRESTAMOSS",
    "ASOC. DUARTE DE AHORROS Y PRESTAMOS",
    "ASOC. LA VEGA REAL DE AHORROS Y PRESTAMOS",
    "ASOC. MANAGUA DE AHORROS Y PRESTAMOS",
    "ASOC. MOCANA DE AHORROS Y PRESTAMOS",
    "ASOC. PERAVIA DE AHORROS Y PRESTAMOS",
    "ASOC. ROMANA DE AHORROS Y PRESTAMOS",
    "BANCO CENTRAL DE LA REPUBLICA DOMINICANA",
    "BANCO LOPEZ DE HARO",
    "BANCAMERICA",
    "BANCO AHORRO Y CREDITO FEDERAL",
    "BANCO ADOPEM",
    "BANCO ATLANTICO",
    "BANCO ATLAS",
    "BANCO BACC DE AHORRO Y CREDITO DEL CARIBE, S. A.",
    "BANCO BANCOTUI",
    "BANCO BDI",
    "BANCO BELLBANK",
    "BANCO COFACI",
    "BANCO CONFISA",
    "BANCO DE AHORRO Y CREDITO FONDESA, S.A. (BANFONDESA)",
    "BANCO DE AHORRO Y CREDITO JMMB BANK, S.A",
    "BANCO DE DESARROLLO ADEMI",
    "BANCO EMPIRE",
    "BANCO FIHOGAR",
    "BANCO GRUFICORP",
    "BANCO MULTIPLE ACTIVO DOMINICANA, S.A.",
    "BANCO MULTIPLE LAFISE",
    "BANCO NACIONAL DE LAS EXPORTACIONES (BANDEX)",
    "BANCO PROMERICA",
    "BANCO UNION",
    "BANCO VIMENCA",
    "BANESCO",
    "BONANZA BANCO DE AHORRO Y CREDITO",
    "CITIBANK",
    "CORPORACION DE CREDITO LEASING CONFISA, S.A.",
    "CORPORACION DE CREDITO NORDESTANA DE PRESTAMOS, S.A.",
    "MOTOR CREDITO, S. A., BANCO DE AHORRO Y CREDITO"
]

export const PropertyType = [
    "Terreno vacío",
    "Terreno con mejora en construcción",
    "Casa de 1 nivel",
    "Casa de 2 niveles",
    "Casa de 3 niveles",
    "Apartamento",
    "Edificio de apartamentos",
    "Torre de apartamentos",
    "Local comercial",
    "Edificio de locales comerciales",
    "Terreno con cultivos",
    "Nave industrial",
    "Finca"
]

export const WayOfWorking = [
    "Independiente",
    "Empleado Privado",
    "Empleado Publico",
    "Empresario Independiente"
]

export const DEFAULT_THEME = {
    BASE_COLOR: "#8582D1",
    SECONDARY_COLOR: "#9ee5b5"
};

export const ENGAGEMENT_STATUS = {
    SENT: {
        label: "Sent",
        icon: "icon-sent"
    },
    SUBMITTED: {
        label: "Submitted",
        icon: "icon-submitted"
    },
    OPENED: {
        label: "Opened",
        icon: "icon-opened"
    }
}

export const ROLES = {
    ADMINS: "admins",
    USERS: "users"
}

export const ENGAGEMENTS_TEMPLATE_CONFIG = {
    "dealer-content": {
        name: "Dealer Content",
        documentTypes: ["pdf"]
    },
    "view-vehicle": {
        name: "Vehicle Profiles",
        documentTypes: ["pdf"]
    },
    "trade-walk": {
        name: "Trade-in Form",
        documentTypes: ["pdf"]
    },
    "finance-products": {
        name: "Finance Products",
        documentTypes: ["pdf"]
    },
    "search-hub": {
        name: "Search Hub",
        documentTypes: ["pdf"]
    },
    "credit-app": {
        name: "Credit Application",
        documentTypes: ["pdf"]
    },
    "get-referrals": {
        name: "Get Referrals",
        documentTypes: ["pdf"]
    },
    "get-docs": {
        name: "Get Docs",
        documentTypes: []
    },
    "needs-analysis": {
        name: "Needs Analysis",
        documentTypes: ["pdf"]
    },
    "payoff-form": {
        name: "Payoff",
        documentTypes: ["pdf"]
    },
    "validate-sold": {
        name: "Validate sold",
        documentTypes: ["pdf"]
    },
    "add-trade": {
        name: "Add Trade",
        documentTypes: ["pdf"]
    },
    "esign-docs": {
        name: "Legal Documents",
        documentTypes: ["pdf"]
    },
    "download-app": {
        name: "Download App",
        documentTypes: ["pdf"]
    },
    default: {
        name: "Engagement Post",
        documentTypes: ["pdf"]
    }
}
