<template>
    <div class="esign-docs">
        <h4 class="section-title">
            eSign Docs
            <label>
                <input v-model="status" name="status" type="checkbox">
                Active
            </label>
        </h4>
        <file-list
            :files="customerPdfs"
            title="Customer PDFs"
            fa-icon="fa-user"
            @update-file="(file, index) => updateFileSchemas(file, index, 'customer_pdfs')"
            @delete-file="(index) => deleteFile(index, 'customer_pdfs')"
        />
        <uploader
            :upload-in-progress="uploadInProgress"
            message="Select customer PDFs to upload."
            type="customer_pdfs"
            @upload="updateCustomerPdfs"
            @uploading="(state) => uploadInProgress = state"
        />
        <file-list
            :files="businessPdfs"
            title="Buisness PDFs"
            fa-icon="fa-building"
            class="mt-4"
            @update-file="(file, index) => updateFileSchemas(file, index, 'business_pdfs')"
        />
        <uploader
            :upload-in-progress="uploadInProgress"
            message="Select buisness PDFs to upload."
            type="business_pdfs"
            @upload="updateBusinessPdfs"
            @uploading="(state) => uploadInProgress = state"
        />
    </div>
</template>

<script>
import FileList from "./components/file-list";
import Uploader from "./components/uploader";

export default {
    name: "ActionsEsignDocs",
    components: {
        FileList,
        Uploader
    },
    data() {
        return {
            id: 0,
            actionId: 0,
            formConfig: [],
            isLoading: true,
            uploadInProgress: false,
            status: false
        }
    },
    computed: {
        customerPdfs() {
            return this.formConfig.customer_pdfs || [];
        },
        businessPdfs() {
            return this.formConfig.business_pdfs || [];
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        async initialize() {
            await this.fetchData();
            this.isLoading = false;
        },
        async fetchData() {
            await axios({
                url: "/actions/esign-docs"
            }).then(({ data: action }) => {
                this.id = action.id;
                this.actionId = action.actions_id;
                this.status = Boolean(Number(action.is_active));
                this.formConfig = action.form_config;

                if (Array.isArray(this.formConfig)) {
                    this.formConfig = {};
                }
            }).catch(() => {});
        },
        async updateAction() {
            await axios({
                method: "PUT",
                url: `/actions/${this.id}`,
                data: {
                    id: this.id,
                    actions_id: this.actionId,
                    form_config: JSON.stringify(this.formConfig),
                    is_active: this.status
                }
            });
        },
        updateCustomerPdfs(data) {
            if (!("customer_pdfs" in this.formConfig)) {
                this.$set(this.formConfig, "customer_pdfs", []);
            }

            for (const file of data) {
                this.formConfig.customer_pdfs.push({
                    id: file.id,
                    title: file.title,
                    status: "waiting",
                    pages: file.pages,
                    url: file.url,
                    filename: file.name,
                    pdf_schema: [],
                    form_schema: [],
                    linked_fields: [],
                    initial_values: []
                });
            }

            this.updateAction();
        },
        updateBusinessPdfs(data) {
            if (!("business_pdfs" in this.formConfig)) {
                this.$set(this.formConfig, "business_pdfs", []);
            }

            for (const file of data) {
                this.formConfig.business_pdfs.push({
                    id: file.id,
                    title: file.title,
                    pages: file.pages,
                    url: file.url,
                    filename: file.name,
                    pdf_schema: [],
                    form_schema: [],
                    linked_fields: [],
                    initial_values: []
                });
            }

            this.updateAction();
        },
        updateFileSchemas(file, index, type) {
            const parsedFile = Object.assign({}, file);
            parsedFile.pdf_schema = JSON.parse(parsedFile.pdf_schema);
            parsedFile.form_schema = JSON.parse(parsedFile.form_schema);
            parsedFile.linked_fields = JSON.parse(parsedFile.linked_fields);
            parsedFile.initial_values = JSON.parse(parsedFile.initial_values || "{}");

            this.$set(this.formConfig[type], index, parsedFile);
            this.updateAction();
            this.$modal.hide("actions-file-modal");
        },
        deleteFile(index, type) {
            this.formConfig[type].splice(index, 1);
            this.updateAction();
        }
    }
}
</script>

<style lang="scss" scoped>
.esign-docs {
    position: relative;

    h4.section-title {
        margin-bottom: 25px;

        label {
            font-size: 80%;
            margin-left: 100px;
            text-transform: capitalize;
        }
    }

    h5.pdf-title {
        border-bottom: 1px solid #ccc;
        color: #555;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 8px;
    }

    .pdf-list {
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;

        div {
            padding: 4px  5px;

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

    .uploader-container {
        border-bottom: 1px dashed #ccc;
        padding-bottom: 10px;

        .uploader-title {
            color: #555;
            padding-bottom: 4px;
        }

        .uploader-bar {
            width: 50%
        }
    }

    .file-list {
        .file-item {
            align-items: center;
            display: flex;
            height: 40px;
            padding: 0 5px;

            .file-name,
            .file-edit {
                width: 300px;
            }

            .file-title {
                margin-right: 10px;
                width: 350px;

                label {
                    margin: 0 10px 0 0;
                }
            }

            .file-actions {
                display: none;

                i {
                    cursor: pointer;
                    margin-right: 10px;
                }

                .fa-pencil-square-o {
                    color: var(--primary);
                }

                .fa-remove {
                    color: var(--red);
                }
            }

            &:hover {
                background-color: #f0f0f0;
                .file-actions {
                    display: block;
                }
            }
        }
    }
}
</style>
