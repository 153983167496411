<template>
    <div>
        <h5 class="file-list__title">
            <i v-if="faIcon" :class="faIcon" class="fa" />
            {{ title }}
        </h5>
        <div class="card">
            <div v-if="files.length" class="file-list__list">
                <div v-for="(file, index) in files" :key="`pdf-${file.id}`" class="file-list__item">
                    <div class="file-list__name">
                        <i class="fa fa-file-o" />
                        {{ file.title }}
                    </div>
                    <div v-if="showActions" class="file-list__actions">
                        <i class="fa fa-pencil-square-o" @click="editPdfSchemas(file, index)" />
                        <i class="fa fa-trash ml-2" @click="deleteFile(index)" />
                    </div>
                </div>
            </div>
            <div v-else class="empty-list">
                There are no uploaded files.
            </div>
        </div>
    </div>
</template>

<script>
import ActionsFileModal from "./file-modal";

export default {
    name: "ActionsFileList",
    components: {
        // eslint-disable-next-line vue/no-unused-components
        ActionsFileModal
    },
    props: {
        title: {
            type: String,
            required: true
        },
        faIcon: {
            type: String,
            default: ""
        },
        files: {
            type: Array,
            default: () => ([])
        },
        showActions: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        editPdfSchemas(file, index) {
            this.$modal.show(ActionsFileModal, {
                file,
                index,
                cancel: () => {
                    this.$modal.hide("actions-file-modal");
                },
                save: () => {
                    this.$emit("update-file", file, index);
                }
            }, {
                name: "actions-file-modal",
                clickToClose: false,
                height: "auto",
                width: "80%"
            });
        },
        deleteFile(index) {
            if (confirm("Are you sure you want to delete this file?")) {
                this.$emit("delete-file", index);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.file-list__title {
    color: #555;
    font-size: 20px;
    margin-bottom: 10px;
}

.card {
    .file-list__list {
        .file-list__item {
            display: flex;
            padding: 4px  5px;

            &:hover {
                background-color: #f0f0f0;

                .file-list__actions {
                    display: block;
                }
            }

            .file-list__name {
                width: 500px;
            }

            .file-list__actions {
                cursor: pointer;
                display: none;

                .fa-trash {
                    color: var(--danger);
                }
            }
        }
    }
}
</style>
