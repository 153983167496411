<template>
    <div class="categories-videos">
        <span class="add-category" @click="addCategory()">
            <i class="fa fa-plus" />
            Add Cateogry
        </span>
        <div v-if="isLoading" class="text-center">
            <loader :size="100" />
        </div>
        <form v-else @submit.prevent="submit()">
            <div class="form-content">
                <div
                    v-for="(cateogry, cIndex) in content"
                    :key="`chunk-${cIndex}`"
                    class="card"
                >
                    <i v-if="content.length > 1" class="fa fa-remove" @click="removeCategory(cIndex)" />
                    <div class="form-group row">
                        <div class="col-sm-3 label">
                            Title:
                        </div>
                        <div class="col-sm-9">
                            <tooltip
                                :manual="true"
                                :value="errors.has(`category-${cIndex}`)"
                                content="required"
                                placement="top-end"
                                effect="dark"
                            >
                                <input
                                    v-model="cateogry.name"
                                    v-validate="{ required: true }"
                                    :name="`category-${cIndex}`"
                                    :class="{ error: errors.has(`category-${cIndex}`) }"
                                    type="text"
                                    class="form-control"
                                >
                            </tooltip>
                        </div>
                    </div>
                    <div
                        v-for="(video, vIndex) in cateogry.videos"
                        :key="`video-${cIndex}-${vIndex}`"
                        class="form-group row"
                    >
                        <div class="col-sm-3 label">
                            Video Link {{ vIndex + 1 }}:
                        </div>
                        <div class="col-sm-9 video-link">
                            <tooltip
                                :manual="true"
                                :value="errors.has(`video-${cIndex}-${vIndex}`)"
                                :content="errors.first(`video-${cIndex}-${vIndex}`)"
                                placement="top-end"
                                effect="dark"
                            >
                                <input
                                    v-model="cateogry.videos[vIndex]"
                                    v-validate="{ required: true, url: { require_protocol: true } }"
                                    :name="`video-${cIndex}-${vIndex}`"
                                    :class="{ 'pad-32': cateogry.videos.length > 1, error: errors.has(`video-${cIndex}-${vIndex}`) }"
                                    type="text"
                                    class="form-control"
                                >
                            </tooltip>
                            <i v-if="cateogry.videos.length > 1" class="fa fa-minus" @click="removeVideoLink(cIndex, vIndex)" />
                        </div>
                    </div>
                    <div class="add-video-link">
                        <span @click="addVideoLink(cIndex)">
                            <i class="fa fa-plus" />
                            Add Video Link
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import { Tooltip } from "element-ui";

export default {
    name: "CategoriesVideosTemplate",
    components: {
        Tooltip
    },
    props: {
        categories: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            content: [],
            isLoading: false,
            isSaving: false,
            status: false
        };
    },
    created() {
        this.$validator.localize("en", {
            messages: {
                required: "required",
                url: "invalid url (please use http[s])"
            }
        });

        this.formatContent(_cloneDeep(this.categories));
    },
    methods: {
        // async initialize() {
        //     await this.fetchData();
        //     if (this.content.length === 0) {
        //         this.addCategory();
        //     }
        // },
        // async fetchData() {
        //     await axios({
        //         url: `/actions/${this.action}`
        //     }).then(({ data: action }) => {
        //         this.formatContent(action.form_config);
        //         this.actionId = action.actions_id,
        //         this.status = Boolean(Number(action.is_active));
        //         this.isLoading = false;
        //     });
        // },
        addCategory() {
            this.content.push({
                name: "",
                videos: [""]
            });
        },
        removeCategory(index) {
            this.content.splice(index, 1);
            this.$validator.reset();
        },
        addVideoLink(categoryIndex) {
            this.content[categoryIndex].videos.push("");
        },
        removeVideoLink(categoryIndex, videoIndex) {
            this.content[categoryIndex].videos.splice(videoIndex, 1);
            this.$validator.reset();
        },
        formatContent(content) {
            this.content = content.map((item) => {
                item.videos = Object.values(item.videos);
                return item;
            });

            if (this.content.length === 0) {
                this.addCategory();
            }
        },
        prepareContent() {
            const content = [];

            this.content.forEach((item) => {
                const videos = {};

                item.videos.forEach((video, index) => {
                    videos[`link_${index + 1}`] = video;
                });

                content.push({
                    name: item.name,
                    videos
                });
            });

            return content;
        },
        async getContent() {
            const isValid = await this.$validator.validateAll();

            if (!isValid) {
                return;
            }

            const data = this.prepareContent();

            return data;

            // axios({
            //     method: "PUT",
            //     url: `/actions/${this.actionId}`,
            //     data: {
            //         actions_id: this.actionId,
            //         is_active: this.status,
            //         form_config: JSON.stringify(data)
            //     }
            // });
        }
    }
}
</script>

<style lang="scss" scoped>
.categories-videos {
    position: relative;

    h4.section-title {
        margin-bottom: 25px;

        label {
            font-size: 80%;
            margin-left: 100px;
            text-transform: capitalize;
        }
    }

    .add-category {
        color: var(--base-color);
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        user-select: none;
    }

    .form-content {
        display: flex;
        flex-wrap: wrap;

        .card {
            position: relative;
            flex: 0 1 32%;
            margin-left: 2%;
            padding: 15px 10px;

            &:nth-child(3n+1) {
                margin-left: 0;
            }

            .video-link {
                position: relative;
            }

            .fa-remove {
                background-color: #fff;
                border: 1px solid #dc3545;
                border-radius: 50%;
                color: #dc3545;
                cursor: pointer;
                height: 20px;
                line-height: 18px;
                position: absolute;
                right: 9px;
                text-align: center;
                top: -9px;
                width: 20px;
            }

            .fa-minus {
                color: #dc3545;
                cursor: pointer;
                position: absolute;
                right: 9px;
                top: 9px;
            }

            input[type="text"] {
                padding-left: 12px;

                &.pad-32 {
                    padding-right: 32px;
                }

                &.error {
                    background-color: rgba(220, 53, 69, 0.1);
                }
            }
        }

        .form-group {
            align-items: center;

            &.row {
                margin: 0 0 10px 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            div[class^="col-"] {
                padding: 0;
            }

            div.label {
                padding-right: 8px;
                text-align: right;

                &::after {
                    content: "*";
                    color: #dc3545;
                    font-size: 80%;
                    vertical-align: top;
                }
            }
        }

        .add-video-link {
            display: flex;
            justify-content: flex-end;
            color: var(--base-color);

            span {
                cursor: pointer;
                font-size: 80%;
                user-select: none;
            }
        }

        .btn-loading {
            width: 62px;
        }
    }
}
</style>
