<template>
    <div class="trade-walk">
        <h4 class="section-title">
            Trade Walk
            <label>
                <input v-model="status" name="status" type="checkbox">
                Active
            </label>
        </h4>
        <form @submit.prevent="submit()">
            <div class="form-content">
                <div class="row">
                    <div class="col-sm-6">
                        <h5>
                            Questions
                        </h5>
                        <div
                            v-for="(question, index) in questions"
                            :key="`question-${index}`"
                            class="form-group row"
                        >
                            <div class="col-sm-2 label">
                                Question {{ index + 1 }}:
                            </div>
                            <div class="col-sm-10">
                                <tooltip
                                    :manual="true"
                                    :value="errors.has(`question-${index}`)"
                                    content="required"
                                    placement="top-end"
                                    effect="dark"
                                >
                                    <input
                                        v-model="questions[index]"
                                        v-validate="{ required: true }"
                                        :name="`question-${index}`"
                                        :class="{ 'pad-32': questions.length > 1, error: errors.has(`question-${index}`) }"
                                        type="text"
                                        class="form-control"
                                    >
                                </tooltip>
                                <i v-if="questions.length > 1" class="fa fa-minus" @click="remove('questions', index)" />
                            </div>
                        </div>
                        <div class="add-item-link">
                            <span @click="add('questions')">
                                <i class="fa fa-plus" />
                                Add Question
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <h5>
                            Require photos of the following
                        </h5>
                        <div
                            v-for="(photo, index) in photos"
                            :key="`photo-${index}`"
                            class="form-group row"
                        >
                            <div class="col-sm-2 label">
                                Photo {{ index + 1 }}:
                            </div>
                            <div class="col-sm-10">
                                <tooltip
                                    :manual="true"
                                    :value="errors.has(`photo-${index}`)"
                                    content="required"
                                    placement="top-end"
                                    effect="dark"
                                >
                                    <input
                                        v-model="photos[index]"
                                        v-validate="{ required: true }"
                                        :name="`photo-${index}`"
                                        :class="{ 'pad-32': photos.length > 1, error: errors.has(`photo-${index}`) }"
                                        type="text"
                                        class="form-control"
                                    >
                                </tooltip>
                                <i v-if="photos.length > 1" class="fa fa-minus" @click="remove('photos', index)" />
                            </div>
                        </div>
                        <div class="add-item-link">
                            <span @click="add('photos')">
                                <i class="fa fa-plus" />
                                Add Photo
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button type="submit" class="btn btn-primary">
                    Save
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { Tooltip } from "element-ui";

export default {
    name: "ActionsTradeWalk",
    components: {
        Tooltip
    },
    data() {
        return {
            id: 0,
            actionId: 0,
            isLoading: true,
            photos: [],
            questions: [],
            status: false
        };
    },
    created() {
        this.initialize();
    },
    methods: {
        async initialize() {
            await this.fetchData();
            this.isLoading = false;
        },
        async fetchData() {
            await axios({
                url: "/actions/trade-walk"
            }).then(({ data: action }) => {
                this.formatContent(action.form_config);
                if (this.questions.length === 0) {
                    this.add("questions");
                }
                if (this.photos.length === 0) {
                    this.add("photos");
                }
                this.id = action.id;
                this.actionId = action.actions_id;
                this.status = Boolean(Number(action.is_active));
            });
        },
        formatContent(data) {
            this.questions = Object.values(data.questions || []);
            this.photos = Object.values(data.photos || []);
        },
        add(what) {
            this[what].push("");
        },
        remove(what, index) {
            this[what].splice(index, 1);
            this.$validator.reset();
        },
        prepareContent() {
            return {
                questions: this.arrayToObject("questions", "question"),
                photos: this.arrayToObject("photos", "photo")
            };
        },
        arrayToObject(what, prefix) {
            const content = {};

            this[what].forEach((item, index) => {
                content[`${prefix}${index + 1}`] = item;
            });

            return content;
        },
        async submit() {
            const isValid = await this.$validator.validateAll();

            if (!isValid) {
                return;
            }

            const data = this.prepareContent();

            axios({
                method: "PUT",
                url: `/actions/${this.id}`,
                data: {
                    actions_id: this.actionId,
                    form_config: JSON.stringify(data),
                    is_active: this.status
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.trade-walk {
    position: relative;

    h4.section-title {
        margin-bottom: 25px;

        label {
            font-size: 80%;
            margin-left: 100px;
            text-transform: capitalize;
        }
    }

    .form-content {
        h5 {
            margin-bottom: 25px;
        }

        .fa-remove {
            background-color: #fff;
            border: 1px solid #dc3545;
            border-radius: 50%;
            color: #dc3545;
            cursor: pointer;
            height: 20px;
            line-height: 18px;
            position: absolute;
            right: 9px;
            text-align: center;
            top: -9px;
            width: 20px;
        }

        .fa-minus {
            color: #dc3545;
            cursor: pointer;
            position: absolute;
            right: 9px;
            top: 9px;
        }

        input[type="text"] {
            padding-left: 12px;

            &.pad-32 {
                padding-right: 32px;
            }

            &.error {
                background-color: rgba(220, 53, 69, 0.1);
            }
        }

        .form-group {
            align-items: center;

            &.row {
                margin: 0 0 10px 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            div[class^="col-"] {
                padding: 0;
            }

            div.label {
                padding-right: 8px;
                text-align: right;

                &::after {
                    content: "*";
                    color: #dc3545;
                    font-size: 80%;
                    vertical-align: top;
                }
            }
        }

        .add-item-link {
            display: flex;
            justify-content: flex-end;
            color: var(--base-color);

            span {
                cursor: pointer;
                font-size: 80%;
                user-select: none;
            }
        }
    }
}
</style>
