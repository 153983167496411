<template>
    <div>
        <actions-header v-model="actionData.is_active" :is-loading="isLoading" title="Download App" />
        <div v-if="actionData.form_config" class="card download-app">
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">Banner Image:</label>
                <div class="col-sm-5">
                    <img :src="bannerImage" class="banner-image">
                    <el-upload
                        :auto-upload="false"
                        :file-list="fileList"
                        :on-change="fileChange"
                        :show-file-list="false"
                        class="banner-upload"
                        action=""
                    >
                        <button
                            slot="trigger"
                            :disabled="isSaving"
                            type="button"
                            class="btn btn-primary"
                        >
                            select file
                        </button>
                    </el-upload>
                    <div class="uploader-bar">
                        <el-progress v-if="isSaving" :percentage="uploadPercentage" />
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="app-store-url" class="col-sm-2 col-form-label">App Store URL:</label>
                <div class="col-sm-5">
                    <input
                        id="app-store-url"
                        v-model="actionData.form_config.app_store_url"
                        type="text"
                        class="form-control"
                    >
                </div>
            </div>
            <div class="form-group row">
                <label for="google-play-url" class="col-sm-2 col-form-label">Google Play URL:</label>
                <div class="col-sm-5">
                    <input
                        id="google-play-url"
                        v-model="actionData.form_config.google_play_url"
                        type="text"
                        class="form-control"
                    >
                </div>
            </div>
        </div>
        <categories-videos
            v-if="actionData.form_config"
            ref="categories"
            :categories="categories"
        />
        <div>
            <button v-if="isSaving" type="button" class="btn loading-btn">
                <loader :size="21" />
            </button>
            <button
                v-else
                type="submit"
                class="btn btn-primary"
                @click="saveAction()"
            >
                Save
            </button>
        </div>
    </div>
</template>

<script>
import ActionsHeader from "./components/header";
import CategoriesVideos from "./templates/categories-videos";

export default {
    name: "ActionsDownloadApp",
    components: {
        ActionsHeader,
        CategoriesVideos
    },
    data() {
        return {
            actionData: {},
            bannerImageUrl: "",
            categories: [],
            fileList: [],
            isLoading: true,
            isSaving: false,
            uploadPercentage: 0
        }
    },
    computed: {
        bannerImage() {
            return this.fileList[0] ? URL.createObjectURL(this.fileList[0].raw) : this.actionData.form_config.banner_image;
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        async initialize() {
            await this.fetchData();
        },
        async fetchData() {
            await axios({
                url: `/actions/download-app`
            }).then(({ data: action }) => {
                this.actionData = action;
                this.categories = this.actionData.form_config.categories;
                this.isLoading = false;
            });
        },
        fileChange(file) {
            this.fileList = [file];
        },
        async saveAction() {
            const categories = await this.$refs.categories.getContent();

            if (!categories) {
                return;
            }

            this.isSaving = true;
            this.categories = categories;
            this.actionData.form_config.categories = categories;

            const formData = new FormData();
            formData.append("file", this.fileList[0].raw);

            await axios({
                method: "POST",
                url: "/filesystem",
                data: formData,
                onUploadProgress: (progress) => {
                    this.uploadPercentage = Math.floor((progress.loaded / progress.total) * 100);
                }
            }).then(({ data: files }) => {
                this.actionData.form_config.banner_image = files[0].url;
                this.fileList = [];
            });

            await axios({
                method: "PUT",
                url: `/actions/${this.actionData.id}`,
                data: {
                    ...this.actionData,
                    form_config: JSON.stringify(this.actionData.form_config)
                }
            }).then(() => {
                this.isSaving = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.download-app {
    margin-bottom: 20px;

    .banner-image {
        height: 140px;
        margin-bottom: 8px;
        object-fit: cover;
        width: 477px;
    }

    .form-group {
        label {
            font-size: 14px !important;
        }

        .form-control {
            padding: 8px;
        }
    }

    .col-form-label {
        max-width: 10% !important;
        text-align: right;
    }
}
</style>
