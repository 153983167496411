<template>
    <th
        v-if="isHeader"
        @click="orderBy(rowField, $event)"
        v-html="title"
    />
    <td v-else class="vuetable-td-component-title">
        <div>
            <span>
                {{ value }}
            </span>
            <small>
                {{ contact }}
            </small>
        </div>
    </td>
</template>

<script>
import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin.vue";
import { formatPhoneNational } from "@/utils/helpers";

export default {
    name: "VuetableTitleField",
    searchName: "title",
    mixins: [
        VuetableFieldMixin
    ],
    computed: {
        value() {
            return this.rowData[this.rowField.fieldName];
        },
        contact() {
            const contact = this.rowData.people;
            const phone = contact.phone ? formatPhoneNational(contact.phone) : "";

            return [contact.name, phone, contact.email].filter(v => v).join(" | ");
        }
    },
    methods: {
        orderBy(rowData, $event) {
            this.vuetable.orderBy(rowData, $event);
        }
    }
}
</script>

<style lang="scss" scoped>
.vuetable-td-component-title small {
    display: block;
}
</style>
