<template>
    <signing-docs action="cc-auth" />
</template>

<script>
import SigningDocs from "./templates/signing-docs";

export default {
    name: "ActionsEsignDocs",
    components: {
        SigningDocs
    }
}
</script>
