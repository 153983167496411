import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import store from "@/store";
import Cookies from "js-cookie";

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;

axios.interceptors.request.use(
    config => {
        const token = store.state.User.token || Cookies.get("token");
        const refreshToken = store.state.User.refreshToken || Cookies.get("refresh-token");

        if (token && refreshToken) {
            Cookies.set("token", token);
            Cookies.set("refresh-token", refreshToken);
            store.dispatch("User/setToken", token);
            store.dispatch("User/setRefreshToken", refreshToken);

            config.headers = {
                Authorization: token
            }
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const refreshAuthLogic = failedRequest => axios({
    method: "POST",
    url: "/refresh-token",
    data: {
        access_token: Cookies.get("token"),
        refresh_token: Cookies.get("refresh-token")
    }
}).then(tokenRefreshResponse => {
    store.dispatch("User/setToken", tokenRefreshResponse.data.token);
    store.dispatch("User/setRefreshToken", tokenRefreshResponse.data.refresh_token);
    failedRequest.response.config.headers.Authorization = tokenRefreshResponse.data.token;
    return Promise.resolve();
});

createAuthRefreshInterceptor(axios, refreshAuthLogic);

window.axios = axios;

export default axios;
