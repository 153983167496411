/**
 * Here you specify any global components CSS used throughout the application.
 */
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import CONFIG from "./../config/app-vars";

const appClass = `${CONFIG.domain}-app`;
document.body.classList.add(appClass);
document.documentElement.classList.add(appClass)
