import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import _isEqual from "lodash/isEqual";
import Dashboard from "./views/dashboard";
import Login from "@/views/auth/login.vue";
import SignUp from "@/views/auth/sign-up.vue";
import ForgotPassword from "@/views/auth/forgot-password.vue";
import UsersInvites from "@/views/auth/users-invites.vue";
import UsersInvitesConfirmation from "@/views/auth/users-invites-confirmation.vue";
import ResetPassword from "@/views/auth/reset-password.vue";
import BrowseList from "./views/browse/";
import Pipeline from "@/views/domains/gewaer/pipelines";
import PipelineForm from "@/views/domains/gewaer/pipelines/form";
import createResource from "./views/resources";
import routerValidator from "@/config/routerValidator";
import ActionsManager from "@/views/domains/dealer/actions/manager";
import DealerContent from "@/views/domains/dealer/actions/dealer-content";
import DownloadApp from "@/views/domains/dealer/actions/download-app";
import TradeWalk from "@/views/domains/dealer/actions/trade-walk";
import NeedsAnalysis from "@/views/domains/dealer/actions/needs-analysis";
import GetReferrals from "@/views/domains/dealer/actions/get-referrals";
import EsignDocs from "@/views/domains/dealer/actions/esign-docs";
import CCAuth from "@/views/domains/dealer/actions/cc-auth";
import GetReview from "@/views/domains/dealer/actions/get-review";

const { GwSettingsRoutes } = require(`./import.${process.env.VUE_APP_IMPORTS}`);

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "dashboard",
            component: Dashboard
        },
        {
            path: "/actions/manager",
            name: "actions-manager",
            component: ActionsManager
        },
        {
            path: "/actions/dealer-content",
            name: "actions-dealer-content",
            component: DealerContent
        },
        {
            path: "/actions/download-app",
            name: "actions-download-app",
            component: DownloadApp
        },
        {
            path: "/actions/trade-walk",
            name: "actions-trade-walk",
            component: TradeWalk
        },
        {
            path: "/actions/needs-analysis",
            name: "actions-needs-analysis",
            component: NeedsAnalysis
        },
        {
            path: "/actions/get-referrals",
            name: "actions-get-referrals",
            component: GetReferrals
        },
        {
            path: "/actions/esign-docs",
            name: "actions-esign-docs",
            component: EsignDocs
        },
        {
            path: "/actions/cc-auth",
            name: "actions-cc-auth",
            component: CCAuth
        },
        {
            path: "/actions/get-review",
            name: "actions-get-review",
            component: GetReview
        },
        {
            path: "/users/login",
            name: "login",
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/users/signup",
            name: "signup",
            component: SignUp,
            meta: {
                requiresAuth: false
            },
            beforeEnter: (to, from, next) => {
                if (store.getters["Application/allowUserRegistration"]) {
                    next();
                } else {
                    next({ name: "login" });
                }
            }
        },
        {
            path: "/users/forgot-password",
            name: "forgotPassword",
            component: ForgotPassword,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/users/reset-password/:resetKey",
            name: "resetPassword",
            component: ResetPassword,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/users/invites/:hash",
            name: "usersInvites",
            component: UsersInvites,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/users/link/:hash",
            name: "usersInvitesConfirmation",
            component: UsersInvitesConfirmation,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "*",
            name: "404",
            component: () => import(/* webpackChunkName: "error-404" */ "./views/errors/error-404"),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/forbidden",
            name: "403",
            component: () => import(/* webpackChunkName: "error-403" */ "./views/errors/error-403"),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/pipeline/add",
            name: "pipelineFormAdd",
            component: PipelineForm
        },
        {
            path: "/pipeline/edit/:id",
            name: "pipelineFormEdit",
            component: PipelineForm,
            meta: {
                navigationGuard: {
                    branchSwitch: true
                }
            }
        },
        {
            path: "/pipeline",
            name: "pipelineEmpty",
            component: Pipeline
        },
        {
            path: "/pipeline/:id",
            name: "pipeline",
            component: Pipeline,
            meta: {
                navigationGuard: {
                    branchSwitch: true
                }
            }
        },
        {
            path: "/browse/:resource",
            name: "browse",
            component: BrowseList
        },
        {
            path: "/browse/:resource/create",
            name: "create-resource",
            component: createResource,
            meta: {
                navigationGuard: {
                    branchSwitch: true
                }
            }
        },
        {
            path: "/browse/:resource/edit/:id",
            name: "edit-resource",
            component: createResource,
            meta: {
                navigationGuard: {
                    branchSwitch: true
                }
            }
        },
        {
            path: "/auth/facebook",
            name: "facebook-auth",
            component: () => import(/* webpackChunkName: "facebook-auth" */ "@c/social-auth"),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/create",
            name: "deslindate-create",
            component: () => import(/* webpackChunkName: "deslindate-resources-leads" */ "@v/domains/deslindate/requests-form")
        },
        {
            path: "/created",
            name: "deslindate-request-created",
            component: () => import(/* webpackChunkName: "deslindate-resources-request-created" */ "@v/domains/deslindate/request-created")
        },
        {
            path: "/broker",
            name: "deslindate-broker",
            component: () => import(/* webpackChunkName: "deslindate-resources-broker" */ "@v/domains/deslindate/broker")
        },
        {
            path: "/my-requests",
            name: "deslindate-requests",
            component: () => import(/* webpackChunkName: "deslindate-resources-my-requests" */ "@v/domains/deslindate/my-requests")
        },
        {
            path: "/admin",
            name: "deslindate-admin",
            component: () => import(/* webpackChunkName: "deslindate-resources-admin" */ "@v/domains/deslindate/admin")
        },
        {
            path: "/deslindate-users",
            name: "deslindate-users",
            component: () => import(/* webpackChunkName: "deslindate-resources-users" */ "@v/domains/deslindate/users")
        },
        {
            path: "/deslindate-users-info",
            name: "deslindate-users-info",
            component: () => import(/* webpackChunkName: "deslindate-resources-users" */ "@v/domains/deslindate/users/user-info")
        },
        {
            path: "/topographer",
            name: "deslindate-topographer",
            component: () => import(/* webpackChunkName: "deslindate-resources-topographer" */ "@v/domains/deslindate/topographer")
        },
        {
            path: "/draftsman",
            name: "deslindate-draftsman",
            component: () => import(/* webpackChunkName: "deslindate-resources-draftsman" */ "@v/domains/deslindate/draftsman")
        },
        {
            path: "/appraiser",
            name: "deslindate-appraiser",
            component: () => import(/* webpackChunkName: "deslindate-resources-appraiser" */ "@v/domains/deslindate/appraiser")
        }
    ]
});

router.addRoutes(GwSettingsRoutes);

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth !== false)) {
        routerValidator(to, from).then((routeToGo) => {
            if (_isEqual(routeToGo, to)) {
                next();
            } else {
                next(routeToGo);
            }
        }).catch((routeToGo) => next(routeToGo));
    } else {
        next();
    }
});

export default router;
